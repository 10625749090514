angular.module('fg').directive('fgPropertyFieldCommon', [
  '$timeout',
  'workflowStore',
  'flowinglyModelUtilityService',
  'pubsubService',
  function (
    $timeout,
    workflowStore,
    flowinglyModelUtilityService,
    pubsubService
  ) {
    return {
      restrict: 'AE',
      templateUrl:
        'angular-form-gen/edit/canvas/field/properties/property-field/common.ng.html',
      link: {
        post: function ($scope, $element, $attrs, ctrls) {
          $scope.initialPreviousStep = function () {
            var nodeDataArray = workflowStore.getNodeDataArray();
            var linkDataArray = workflowStore.getLinkDataArray();
            $scope.previousStepsDataSource =
              flowinglyModelUtilityService.getPreviousNodesDataSourceForDropdown(
                $scope.nodeKey,
                nodeDataArray,
                linkDataArray,
                null,
                true
              );

            if ($scope.field.defaultValueOption === 'none') {
              $scope.field.defaultPreviousStepId = undefined;

              if (
                $scope.field.defaultFormFieldId &&
                $scope.previousStepFieldsDataSource
              ) {
                const matchField = $scope.previousStepFieldsDataSource.find(
                  (field) => field.name === $scope.field.defaultFormFieldId
                );

                if (
                  matchField &&
                  matchField.tableSchema === $scope.field.tableSchema
                ) {
                  $scope.field.tableSchema =
                    '[{"id":0,"type":1,"placeholder":"click to edit","header":"header","isRequired":true}]';
                }
              }
            }

            if ($scope.field.defaultValueOption === 'placeholderText') {
              $scope.field.defaultPreviousStepId = undefined;
              $scope.field.defaultFormFieldId = undefined;
            }

            if ($scope.previousStepsDataSource) {
              $timeout(function () {
                const collatedDataSource = $scope.previousStepsDataSource;
                const previousStepId = $scope.field.defaultPreviousStepId;
                const prevIsInList = $scope.previousStepsDataSource.some(
                  (item) => item.Key == previousStepId
                );

                // if step is not in the list of accessible steps, we manually add it
                // but mark it as inaccessible.
                if (!prevIsInList) {
                  const step = nodeDataArray.find(
                    (node) => node.id == previousStepId
                  );
                  if (step) {
                    collatedDataSource.push({
                      Key: step.id,
                      Value: `${step.text} (inaccessible)`
                    });
                  }
                }

                let stepsWithMatchingFields = [];
                for (let step of collatedDataSource) {
                  if (
                    getFieldsOfTypeFromStep(step.Key, $scope.field.type)
                      .length > 0
                  ) {
                    stepsWithMatchingFields.push(step);
                  }
                }

                if (
                  (!$scope.field.defaultPreviousStepId ||
                    !stepsWithMatchingFields.some(
                      (step) => step.Key === $scope.field.defaultPreviousStepId
                    )) &&
                  $scope.field.defaultValueOption === 'previousValue'
                ) {
                  if (stepsWithMatchingFields.length > 0) {
                    $scope.field.defaultPreviousStepId =
                      stepsWithMatchingFields[0].Key;
                  } else {
                    $scope.field.defaultPreviousStepId = undefined;
                  }
                }
                if (stepsWithMatchingFields.length > 0) {
                  angular
                    .element(`#${$scope.field.name}_previous_step`)
                    .kendoComboBox({
                      dataSource: stepsWithMatchingFields,
                      dataTextField: 'Value',
                      dataValueField: 'Key',
                      value: $scope.field.defaultPreviousStepId,
                      clearButton: false
                    });

                  $scope.onPreviousStepChange();
                }
              });
            }
          };

          $scope.onPreviousStepChange = function () {
            if (
              $scope.field.defaultValueOption !== 'previousValue' ||
              !$scope.previousStepsDataSource ||
              !$scope.field.defaultPreviousStepId
            ) {
              return;
            }

            $scope.previousStepFieldsDataSource = getFieldsOfTypeFromStep(
              $scope.field.defaultPreviousStepId,
              $scope.field.type
            );

            // check if the defaultFormFieldId still exist for newly loaded previous step
            if (!!$scope.field.defaultFormFieldId) {
              const isMatchingField = $scope.previousStepFieldsDataSource.some(
                (field) => field.name === $scope.field.defaultFormFieldId
              );

              if (!isMatchingField) {
                $scope.field.defaultFormFieldId = undefined;
              }
            }

            if ($scope.previousStepFieldsDataSource.length > 0) {
              angular
                .element(`#${$scope.field.name}_previous_step_field`)
                .kendoComboBox({
                  dataSource: $scope.previousStepFieldsDataSource,
                  dataTextField: 'displayName',
                  dataValueField: 'name',
                  value: $scope.field.defaultFormFieldId,
                  clearButton: false
                });

              if (!$scope.field.defaultFormFieldId) {
                $scope.field.defaultFormFieldId =
                  $scope.previousStepFieldsDataSource[0].name;
                $scope.onPreviousFieldChange();
              }
            }
          };

          function getFieldsOfTypeFromStep(stepId, typeName) {
            const step = workflowStore.getNodeById(stepId);
            let allFieldsInStep = angular.copy(step.Card.formElements);
            let tableFields = allFieldsInStep.filter(
              (field) => field.typeName === 'Table'
            );
            if (tableFields && tableFields.length > 0) {
              let tableCells =
                flowinglyModelUtilityService.transformTableCellToField(
                  flowinglyModelUtilityService.getEligibleCellsInFields(
                    tableFields
                  )
                );
              allFieldsInStep = allFieldsInStep.concat(tableCells);
            }

            typeName = typeName.toLowerCase();
            return allFieldsInStep.filter(
              (field) => field.type.toLowerCase() === typeName
            );
          }

          $scope.onPreviousFieldChange = function () {
            if ($scope.field.typeName !== 'Table') {
              return;
            }

            const matchField = $scope.previousStepFieldsDataSource.find(
              (field) => field.name === $scope.field.defaultFormFieldId
            );

            if (!matchField) {
              $scope.field.defaultFormFieldId = undefined;
            } else {
              $scope.field.tableSchema = matchField.tableSchema;
            }
          };

          $scope.showPlaceHolder = function () {
            var visiblePlaceHolder = false;
            switch ($scope.field.type) {
              case 'text':
              case 'currency':
              case 'email':
              case 'number':
                visiblePlaceHolder = true;
                break;
              default:
                visiblePlaceHolder = false;
            }
            if (
              !visiblePlaceHolder &&
              $scope.field.defaultValueOption === 'placeholderText'
            ) {
              $scope.field.defaultValueOption = 'none';
            }
            return visiblePlaceHolder;
          };

          $scope.setplaceholder = function () {
            if ($scope.field.defaultValueOption === 'none') {
              $scope.field.placeholder = '';

              $scope.initialPreviousStep();
            }
          };

          $scope.$watch($attrs['fgPropertyFieldCommon'], function (value) {
            $scope.fields = angular.extend($scope.fields, value);
          });

          $scope.$watch(
            function () {
              return $scope.field.displayName;
            },
            function (newValue, oldValue) {
              if (newValue !== oldValue) {
                pubsubService.publish('FORM_FIELD_NAME_OR_STEP_NAME_CHANGED', {
                  isFieldChange: true,
                  fieldId: $scope.field.id,
                  oldValue: oldValue,
                  newValue: newValue
                });
              }
            }
          );

          $scope.fields = {
            fieldname: false,
            label: false,
            displayname: false,
            placeholder: false,
            tooltip: false,
            focus: false,
            previousfield: false
          };
          $scope.field.defaultValueOption =
            $scope.field.defaultValueOption || 'none';
          $scope.initialPreviousStep();
        }
      }
    };
  }
]);
